@use "react-widgets/scss/styles" as * with ($widget-font-size: 12px,
    $list-selected-bg: #d9d7d7,
    // $list-focus-border-color: red,
    $list-selected-color: black,
    $input-height: 2.2em);

$small: 300px;
$medium: 900px;
@media screen and (max-width: $small) {
    .rw-widget {
        font-size: 10px;
    }

    .rw-list-option-create {
        display: block;
        font-size: 10px;
        padding: 0.25em 1.5em;
    }

}

@media screen and (min-width: $medium) {
    .rw-widget {
        font-size: 12px;
    }

    .rw-list-option-create {
        display: block;
        font-size: 12px;
        padding: 0.25em 1.5em;
    }
}


.rw-list-option-create {
    /* user-select: none; */
    color: #212529;
    cursor: pointer;
    /*border: 0px solid transparent;*/
    border: none;
    background-clip: padding-box;
}

.rw-state-selected.rw-list-option-create {
    background-color: #d9d7d7;
    /*border-color: #d9d7d7;*/
    /*color: #fff;*/
}



// .rw-listbox {
//     border-radius: 0px;
//     background-color: #fff;
//     /*border: #ccc 0px solid;*/
//     border: none;
// }

.rw-listbox.rw-state-focus {
    border-color: #d9d7d7;
    //border-color: red;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25), inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: box-shadow 0.15s ease-in-out;
}

.rw-calendar-popup {
    right: auto;
    min-width: 0;
    width: 16em;
    left: -50px
}